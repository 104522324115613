<template>
    <div class="nk-header nk-header-fluid nk-header-fixed is-light">
        <div class="container-fluid">
            <div class="nk-header-wrap">
                <div class="nk-menu-trigger d-xl-none ml-n1">
                    <a href="#" class="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em class="icon ni ni-menu"></em></a>
                </div>
                <div class="nk-header-brand d-xl-none">
                    <router-link :to="{name:'home'}" class="logo-link">
                        <img class="logo-dark logo-img logo-img-xs" src="../assets/images/rabbani.png" alt="logo">
                    </router-link>
                </div>
                <div class="nk-header-news d-none d-xl-block">
                    <div class="nk-news-list">
                        <a class="nk-news-item" href="#">
                            <div class="nk-news-icon"><em class="icon ni ni-card-view"></em></div>
                            <div class="nk-news-text"><p>Rabbani Admin Panel V.2.0</p></div>
                        </a>
                    </div>
                </div>
                <div class="nk-header-tools">
                    <ul class="nk-quick-nav">
                        <li class="dropdown notification-dropdown mr-n1">
                            <a href="#" class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                                <div class="icon-status icon-status-na"><em class="icon ni ni-setting"></em></div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
                                <div class="dropdown-inner">
                                    <ul class="link-list">
                                        <li><a class="dark-switch" href="#"><em class="icon ni ni-moon"></em><span>Dark Mode</span></a></li>
                                        <li><a @click.prevent="processLogout" href="#"><em class="icon ni ni-signout"></em><span>Exit Panel</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </li><!-- .dropdown -->
                        <li class="dropdown notification-dropdown mr-n1">
                            <a href="#" class="dropdown-toggle nk-quick-nav-icon" data-toggle="dropdown">
                                <div class="icon-status icon-status-info"><em class="icon ni ni-bell"></em></div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1">
                                <div class="dropdown-head">
                                    <span class="sub-title nk-dropdown-title">Notification</span>
                                </div>
                                <div class="dropdown-body">
                                    <div class="nk-notification">
                                        <div class="nk-notification-item dropdown-inner">
                                            <div class="nk-notification-text w-100 text-center" style="font-size: 12px;"><i>"No notifications yet"</i></div>
                                        </div><!-- .dropdown-inner -->
                                    </div>
                                </div><!-- .nk-dropdown-body -->
                            </div>
                        </li><!-- .dropdown -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { confirmAlert, loadingAlert } from '../helpers/global'
export default {
    methods: {
        ...mapActions(['logOut']),
        async processLogout() {
            confirmAlert({type:'question',title:'Logout',text:'Yakin ingin keluar dashboard panel?',confirmLabel:'Iya, Keluar'}).then((result) => {
              if (result.isConfirmed) {
                loadingAlert({text:'Proses permintaan.....', timer:1000});
                this.logOut(); this.$router.push({'name':'login'});
              }
            })
        },
    },
}
</script>
import { createRouter, createWebHistory, useRouter } from 'vue-router'
import store from "../config/store";

//define a routes
const routes = [
    {
        path: '/', alias:'/home', name: 'home',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true },
        component: () => import('@/views/Home/Index.vue')
    },
    {
        path: '/pagenotfound', name: 'pagenotfound',
        meta: { readyPage:true, isDashboard:false },
        component: () => import('@/views/Page/NotFound.vue')
    },
    {
        path: '/login', name: 'login',
        meta: { readyPage:true, guestPage: true, isDashboard:false },
        component: () => import('@/views/Home/Login.vue')
    },
    {
        path: '/catalog', name: 'catalog', alias:'/catalog-list',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'catalog' },
        component: () => import('@/views/Catalog/Index.vue')
    },
    {
        path: '/catalog/:id', name: 'catalog.detail',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'catalog' },
        component: () => import('@/views/Catalog/Detail.vue')
    },
    {
        path: '/catalog-gallery/:id', name: 'catalog.gallery',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'catalog' },
        component: () => import('@/views/Catalog/Gallery.vue')
    },
    {
        path: '/catalog-banner', name: 'catalog.banner',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'catalog' },
        component: () => import('@/views/Catalog/Banner.vue')
    },
    {
        path: '/catalog-banner/:id', name: 'catalog.detail.banner',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'catalog' },
        component: () => import('@/views/Catalog/DetailBanner.vue')
    },

    //================== MEMBER DIGITAL ==============//
    {
        path: '/memberdigital', name: 'memberdigital', alias:'/member-digital',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'memberdigital' },
        component: () => import('@/views/MemberDigital/Index.vue')
    },
    {
        path: '/memberdigital/:id', name: 'memberdigital.detail',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'memberdigital' },
        component: () => import('@/views/MemberDigital/Detail.vue')
    },
    {
        path: '/memberdigital-report', name: 'memberdigital.report',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'memberdigital' },
        component: () => import('@/views/MemberDigital/Report.vue')
    },

    //================== MEMBER DIGITAL PARENT ==============//
    {
        path: '/memberdigital-parent', name: 'memberdigital.parent', alias:'/member-digital-parent',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'memberdigital' },
        component: () => import('@/views/MemberDigital/Parent/Index.vue')
    },
    {
        path: '/memberdigital-parent/:id', name: 'memberdigital.parent.detail',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'memberdigital' },
        component: () => import('@/views/MemberDigital/Parent/Detail.vue')
    },

    //================== MEMBER DIGITAL STUDENT ==============//
    {
        path: '/student', name: 'student',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'memberdigital' },
        component: () => import('@/views/Student/Index.vue')
    },
    {
        path: '/student/:id', name: 'student.detail',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'memberdigital' },
        component: () => import('@/views/Student/Detail.vue')
    },
    {
        path: '/student-import/', name: 'student.import',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'memberdigital' },
        component: () => import('@/views/Student/Import.vue')
    },

    //================== FULFILLMENT ==============//
    {
        path: '/inbound-scan', name: 'inbound.scan', alias:'/inbound',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'inbound' },
        component: () => import('@/views/Fulfillment/Inbound/Scan.vue')
    },
    {
        path: '/inbound-stocktransfer', name: 'inbound.stock.transfer', alias:'/inbound-stock-transfer',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'inbound' },
        component: () => import('@/views/Fulfillment/Inbound/StockTransfer.vue')
    },
    {
        path: '/inbound-rack', name: 'inbound.rack',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'inbound' },
        component: () => import('@/views/Fulfillment/Inbound/Rack.vue')
    },
    {
        path: '/inbound-rack/:id', name: 'inbound.rack.detail',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'inbound' },
        component: () => import('@/views/Fulfillment/Inbound/DetailRack.vue')
    },
    {
        path: '/inbound-room', name: 'inbound.room',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'inbound' },
        component: () => import('@/views/Fulfillment/Inbound/Room.vue')
    },
    {
        path: '/inbound-room/:id', name: 'inbound.room.detail',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'inbound' },
        component: () => import('@/views/Fulfillment/Inbound/DetailRoom.vue')
    },
    {
        path: '/inbound-box', name: 'inbound.box',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'inbound' },
        component: () => import('@/views/Fulfillment/Inbound/Box.vue')
    },
    {
        path: '/inbound-box/:id', name: 'inbound.box.detail',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'inbound' },
        component: () => import('@/views/Fulfillment/Inbound/DetailBox.vue')
    },
    {
        path: '/inbound-product', name: 'inbound.product',
        meta: { readyPage:false, requiresAuth: true, isDashboard:true, parent:'inbound' },
        component: () => import('@/views/Fulfillment/Inbound/Product.vue')
    },
    {
        path: '/inbound-product/:id', name: 'inbound.product.detail',
        meta: { readyPage:false, requiresAuth: true, isDashboard:true, parent:'inbound' },
        component: () => import('@/views/Fulfillment/Inbound/DetailProduct.vue')
    },

    //================== STORE ==============//
    {
        path: '/store', name: 'store',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'store' },
        component: () => import('@/views/Store/Index.vue')
    },
    {
        path: '/store/:id', name: 'store.detail',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'store' },
        component: () => import('@/views/Store/Detail.vue')
    },
    {
        path: '/store-import/', name: 'store.import',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'store' },
        component: () => import('@/views/Store/Import.vue')
    },
    {
        path: '/store-import-product/:id', name: 'store.import.product',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'store' },
        component: () => import('@/views/Store/ImportProduct.vue')
    },
    {
        path: '/store-import-staff/:id', name: 'store.import.staff',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'store' },
        component: () => import('@/views/Store/ImportStaff.vue')
    },

    //================== DATA LIST ==============//
    {
        path: '/product', name: 'product',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'product' },
        component: () => import('@/views/Product/Index.vue')
    },
    {
        path: '/product/:id', name: 'product.detail',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'product' },
        component: () => import('@/views/Product/Detail.vue')
    },
    {
        path: '/category', name: 'category',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'product' },
        component: () => import('@/views/Category/Index.vue')
    },
    {
        path: '/category/:id', name: 'category.detail',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'product' },
        component: () => import('@/views/Category/Detail.vue')
    },

    //================== DATA LIST ORDER ==============//
    {
        path: '/transaction', name: 'transaction', alias:'/order',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'transaction' },
        component: () => import('@/views/Transaction/Index.vue')
    },
    {
        path: '/transaction/:id', name: 'transaction.detail', alias:'/order/:id',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'transaction' },
        component: () => import('@/views/Transaction/Detail.vue')
    },
    {
        path: '/transaction-new', name: 'transaction.new', alias:'/order-new',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'transaction' },
        component: () => import('@/views/Transaction/Create.vue')
    },

    //================== DATA LIST USER ==============//
    {
        path: '/user', name: 'user', alias:'/member',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'user' },
        component: () => import('@/views/User/Index.vue')
    },
    {
        path: '/user/:id', name: 'user.detail', alias:'/member/:id',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'user' },
        component: () => import('@/views/User/Detail.vue')
    },

    //================== DATA LIST ADMIN ==============//
    {
        path: '/admin', name: 'admin', alias:'/administrator',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'admin' },
        component: () => import('@/views/Admin/Index.vue')
    },
    {
        path: '/admin/:id', name: 'admin.detail', alias:'/administrator/:id',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'admin' },
        component: () => import('@/views/Admin/Detail.vue')
    },

    //================== FITUR MARKETING ==============//
    {
        path: '/broadcast-bulkfile', name: 'broadcast.bulkfile',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'broadcast' },
        component: () => import('@/views/Marketing/Broadcast/BulkFile.vue')
    },
    {
        path: '/promo-discount', alias:'/discount', name: 'promo.discount',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'promo' },
        component: () => import('@/views/Marketing/Promo/Discount.vue')
    },
    {
        path: '/promo-flashsale', alias:'/flashsale', name: 'promo.flashsale',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'promo' },
        component: () => import('@/views/Marketing/Promo/FlashSale.vue')
    },
    {
        path: '/flashsale-new', name: 'promo.flashsale.new', alias:'/promo-flashsale-new',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'promo' },
        component: () => import('@/views/Marketing/Promo/FlashSaleNew.vue')
    },
    {
        path: '/promo-voucher', alias:'/voucher', name: 'promo.voucher',
        meta: { readyPage:true, requiresAuth: true, isDashboard:true, parent:'promo' },
        component: () => import('@/views/Marketing/Promo/Voucher.vue')
    },

]

//create router
const router = createRouter({ history: createWebHistory(), useroute: useRouter(), routes })

router.beforeEach((to, from, next) => {
    if(to.matched.some((record) => record.meta.readyPage)) {
        if(to.matched.some((record) => record.meta.requiresAuth)) {
            if (store.getters.isAuthenticated) { next(); return; }
            next("/login");
        }else if(to.matched.some((record) => record.meta.guestPage)) {
            if (store.getters.isAuthenticated) {  next("/"); return; }
            next();
        }else{ next(); }
    }else{ next('/pagenotfound'); }
});

export default router
<template>
    <div class="nk-sidebar nk-sidebar-fixed" data-content="sidebarMenu">
        <div class="nk-sidebar-element nk-sidebar-head">
            <div class="nk-sidebar-brand">
                <router-link :to="{name:'home'}" class="logo-link nk-sidebar-logo">
                    <img class="logo-dark logo-img logo-img-xs" src="../assets/images/rabbani.png" alt="logo">
                </router-link>
            </div>
            <div class="nk-menu-trigger mr-n2">
                <a href="#" class="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em class="icon ni ni-arrow-left"></em></a>
            </div>
        </div>
        <div class="nk-sidebar-element">
            <div class="nk-sidebar-body" data-simplebar>
                <div class="nk-sidebar-content" style="overflow:auto;">
                    <div class="nk-sidebar-widget d-none d-xl-block hide_element">
                        <div class="user-account-info between-center">
                            <div class="user-account-main">
                                <h6 class="overline-title-alt">Available Balance</h6>
                                <div class="user-balance">2.014095 <small class="currency currency-btc">BTC</small></div>
                                <div class="user-balance-alt">18,934.84 <span class="currency currency-btc">BTC</span></div>
                            </div>
                            <a href="#" class="btn btn-white btn-icon btn-light"><em class="icon ni ni-line-chart"></em></a>
                        </div>
                        <ul class="user-account-data gy-1">
                            <li>
                                <div class="user-account-label">
                                    <span class="sub-text">Profits (7d)</span>
                                </div>
                                <div class="user-account-value">
                                    <span class="lead-text">+ 0.0526 <span class="currency currency-btc">BTC</span></span>
                                    <span class="text-success ml-2">3.1% <em class="icon ni ni-arrow-long-up"></em></span>
                                </div>
                            </li>
                            <li>
                                <div class="user-account-label">
                                    <span class="sub-text">Deposit in orders</span>
                                </div>
                                <div class="user-account-value">
                                    <span class="sub-text">0.005400 <span class="currency currency-btc">BTC</span></span>
                                </div>
                            </li>
                        </ul>
                        <div class="user-account-actions">
                            <ul class="g-3">
                                <li><a href="#" class="btn btn-lg btn-primary"><span>Deposit</span></a></li>
                                <li><a href="#" class="btn btn-lg btn-warning"><span>Withdraw</span></a></li>
                            </ul>
                        </div>
                    </div><!-- .nk-sidebar-widget -->
                    <div class="nk-sidebar-menu pt-3">
                        <!-- Menu -->
                        <ul class="nk-menu">
                            <li class="nk-menu-item" :class="[isMenu=='home' ? 'active' : '']">
                                <router-link :to="{name: 'home'}" class="nk-menu-link">
                                    <span class="nk-menu-icon"><em class="icon ni ni-dashboard"></em></span>
                                    <span class="nk-menu-text">Dashboard</span>
                                </router-link>
                            </li>
                            <li class="nk-menu-heading pt-3">
                                <h6 class="overline-title text-primary-alt">Applications</h6>
                            </li>
                            <li class="nk-menu-item has-sub" :class="[isMenu=='catalog' ? 'active' : '']" is-show="hide" data-menu="menu-catalog">
                                <a href="#" class="nk-menu-link nk-menu-toggle" data-target="menu-catalog">
                                    <span class="nk-menu-icon"><em class="icon ni ni-text-rich"></em></span>
                                    <span class="nk-menu-text">Catalog</span>
                                </a>
                                <ul class="nk-menu-sub sub-menu-item" id="sub-menu-catalog">
                                    <li class="nk-menu-item" id="menu-catalog-list">
                                        <router-link :to="{name: 'catalog'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Catalog List</span></router-link>
                                    </li>
                                    <li class="nk-menu-item" id="menu-catalog-banner">
                                        <router-link :to="{name: 'catalog.banner'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Banner (Slider)</span></router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="nk-menu-item has-sub" :class="[isMenu=='memberdigital' ? 'active' : '']" is-show="hide" data-menu="menu-memberdigital">
                                <a href="#" class="nk-menu-link nk-menu-toggle" data-target="menu-memberdigital">
                                    <span class="nk-menu-icon"><em class="icon ni ni-users"></em></span>
                                    <span class="nk-menu-text">Member Digital</span>
                                </a>
                                <ul class="nk-menu-sub sub-menu-item" id="sub-menu-memberdigital">
                                    <li class="nk-menu-item" id="menu-memberdigital-list">
                                        <router-link :to="{name: 'memberdigital'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Member List</span></router-link>
                                    </li>
                                    <li class="nk-menu-item" id="menu-memberdigital-parent">
                                        <router-link :to="{name: 'memberdigital.parent'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Member Parent</span></router-link>
                                    </li>
                                    <li class="nk-menu-item" id="menu-memberdigital-student">
                                        <router-link :to="{name: 'student'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Student</span></router-link>
                                    </li>
                                    <li class="nk-menu-item" id="menu-memberdigital-report">
                                        <router-link :to="{name: 'memberdigital.report'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Report</span></router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="nk-menu-heading pt-3">
                                <h6 class="overline-title text-primary-alt">Fulfillment</h6>
                            </li>
                            <li class="nk-menu-item has-sub" :class="[isMenu=='inbound' ? 'active' : '']" is-show="hide" data-menu="menu-inbound">
                                <a href="#" class="nk-menu-link nk-menu-toggle" data-target="menu-inbound">
                                    <span class="nk-menu-icon"><em class="icon ni ni-inbox"></em></span>
                                    <span class="nk-menu-text">Inbound</span>
                                </a>
                                <ul class="nk-menu-sub sub-menu-item" id="sub-menu-inbound">
                                    <li class="nk-menu-item" id="menu-inbound-scan">
                                        <router-link :to="{name: 'inbound.scan'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Scan</span></router-link>
                                    </li>
                                    <li class="nk-menu-item" id="menu-inbound-stock-transfer">
                                        <router-link :to="{name: 'inbound.stock.transfer'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Stock Transfer</span></router-link>
                                    </li>
                                    <li class="nk-menu-item" id="menu-inbound-rack">
                                        <router-link :to="{name: 'inbound.rack'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Rack</span></router-link>
                                    </li>
                                    <li class="nk-menu-item" id="menu-inbound-room">
                                        <router-link :to="{name: 'inbound.room'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Room</span></router-link>
                                    </li>
                                    <li class="nk-menu-item" id="menu-inbound-box">
                                        <router-link :to="{name: 'inbound.box'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Box</span></router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="nk-menu-heading pt-3">
                                <h6 class="overline-title text-primary-alt">Fitur</h6>
                            </li>
                            <li class="nk-menu-item" :class="[isMenu=='store' ? 'active' : '']">
                                <router-link :to="{name: 'store'}" class="nk-menu-link">
                                    <span class="nk-menu-icon"><em class="icon ni ni-layers"></em></span>
                                    <span class="nk-menu-text">Store</span>
                                </router-link>
                            </li>
                            <li class="nk-menu-heading pt-3">
                                <h6 class="overline-title text-primary-alt">Marketing</h6>
                            </li>
                            <li class="nk-menu-item has-sub" :class="[isMenu=='broadcast' ? 'active' : '']" is-show="hide" data-menu="menu-broadcast">
                                <a href="#" class="nk-menu-link nk-menu-toggle" data-target="menu-broadcast">
                                    <span class="nk-menu-icon"><em class="icon ni ni-rss"></em></span>
                                    <span class="nk-menu-text">Broadcast</span>
                                </a>
                                <ul class="nk-menu-sub sub-menu-item" id="sub-menu-broadcast">
                                    <li class="nk-menu-item" id="menu-broadcast-bulkfile">
                                        <router-link :to="{name: 'broadcast.bulkfile'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Bulk File</span></router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="nk-menu-item has-sub" :class="[isMenu=='promo' ? 'active' : '']" is-show="hide" data-menu="menu-promo">
                                <a href="#" class="nk-menu-link nk-menu-toggle" data-target="menu-promo">
                                    <span class="nk-menu-icon"><em class="icon ni ni-tags"></em></span>
                                    <span class="nk-menu-text">Promo</span>
                                </a>
                                <ul class="nk-menu-sub sub-menu-item" id="sub-menu-promo">
                                    <li class="nk-menu-item" id="menu-promo-discount">
                                        <router-link :to="{name: 'promo.discount'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Discount</span></router-link>
                                    </li>
                                    <li class="nk-menu-item" id="menu-promo-flashsale">
                                        <router-link :to="{name: 'promo.flashsale'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Flashsale</span></router-link>
                                    </li>
                                    <li class="nk-menu-item" id="menu-promo-voucher">
                                        <router-link :to="{name: 'promo.voucher'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Voucher</span></router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nk-menu-heading pt-3">
                                <h6 class="overline-title text-primary-alt">Data List</h6>
                            </li>
                            <li class="nk-menu-item has-sub" :class="[isMenu=='product' ? 'active' : '']" is-show="hide" data-menu="menu-product">
                                <a href="#" class="nk-menu-link nk-menu-toggle" data-target="menu-product">
                                    <span class="nk-menu-icon"><em class="icon ni ni-package"></em></span>
                                    <span class="nk-menu-text">Product</span>
                                </a>
                                <ul class="nk-menu-sub sub-menu-item" id="sub-menu-product">
                                    <li class="nk-menu-item" id="menu-product-list">
                                        <router-link :to="{name: 'product'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Product List</span></router-link>
                                    </li>
                                    <li class="nk-menu-item" id="menu-product-category">
                                        <router-link :to="{name: 'category'}" href="#" class="nk-menu-link"><span class="nk-menu-text">Category</span></router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="nk-menu-item" :class="[isMenu=='transaction' ? 'active' : '']">
                                <router-link :to="{name: 'transaction'}" class="nk-menu-link">
                                    <span class="nk-menu-icon"><em class="icon ni ni-cc-alt"></em></span>
                                    <span class="nk-menu-text">Transaction</span>
                                </router-link>
                            </li>
                            <li class="nk-menu-item" :class="[isMenu=='user' ? 'active' : '']">
                                <router-link :to="{name: 'user'}" class="nk-menu-link">
                                    <span class="nk-menu-icon"><em class="icon ni ni-user"></em></span>
                                    <span class="nk-menu-text">User</span>
                                </router-link>
                            </li>
                            <li class="nk-menu-item" :class="[isMenu=='admin' ? 'active' : '']">
                                <router-link :to="{name: 'admin'}" class="nk-menu-link">
                                    <span class="nk-menu-icon"><em class="icon ni ni-user-list"></em></span>
                                    <span class="nk-menu-text">Admin</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
      isMenu: { type: String, default: 'home'}
    }
}
</script>